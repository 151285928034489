 <!-- 印章柜预警详情 -->
<template>
    <section v-if="[29, 30, 31, 32, 33, 34].includes(current?.businessType)">
        <a-descriptions title="" layout="vertical" bordered :column="2" size="small" class="descript"
            style="border-top: 1px solid #f0f0f0">
            <a-descriptions-item :label="current?.messageType == 2 ? '预警类型' : '通知类型'">{{
                warningStatusObj[current?.businessType].name || '-'
            }}</a-descriptions-item>
            <a-descriptions-item :label="current?.messageType == 2 ? '预警信息' : '通知信息'">{{
                current?.content || '-'
            }}</a-descriptions-item>
            <a-descriptions-item :label="current?.messageType == 2 ? '预警时间' : '通知时间'">{{
                current?.warningTime || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="印章柜设备编号">{{ current?.cabinetNumber || '-' }}</a-descriptions-item>
            <a-descriptions-item label="格口名称">{{ current?.boxName || '-' }}</a-descriptions-item>
            <a-descriptions-item label="格口编号">{{ current?.boxNumber || '-' }}</a-descriptions-item>
            <a-descriptions-item label="操作人">{{ current?.operateInfo?.staffName || '-' }}</a-descriptions-item>
            <a-descriptions-item v-if="current?.articleInfo?.length > 0"
                :label="current?.messageType == 2 ? '预警物品信息' : '物品信息'">
                <div v-for="item in current.articleInfo" :key="item">
                    <p>
                        <span v-if="item.sealName != null">{{ item.sealName }}-</span>
                        <span v-else>暂无印章-</span>
                        <span>{{ item.number }}</span>
                    </p>
                </div>
            </a-descriptions-item>
        </a-descriptions>
    </section>
</template>
<script setup>
import { defineProps } from 'vue'
import { warningStatusObj } from '@/utils/constData'

const props = defineProps({
    current: {
        type: Object,
        default: () => ({})
    }
})
</script>
<style lang="less" scoped>
@import url('./shareStyle.less');
</style>